<template>
<div class="scroll-y-con">

  <div class="top-title">
    <div>{{`子订单ID: ${orderNo} (${getOrderStatus(data.order_status)})`}}</div>
    <div>
      <!-- <a-button
        v-if="[1,2,3].includes(data.order_status)"
        type="link" class="p-0 mr-4"
        @click="isShowCancel = true"
      >取消订单</a-button> -->
      <a-button 
        v-if="[1,2,3,4].includes(data.order_status)"
        type="link" class="p-0 mr-4"
        @click="()=>{this.isShowPrint = true}"
      >打印</a-button>
      <a-tag v-if="data.status == -1" class="mr-0" color="red">订单已删除</a-tag>
    </div>
  </div>

  <div class="container page-content">
    <a-descriptions bordered title size="middle" class="descri-class" :column="12">
      <!-- 订单信息 -->
      <a-descriptions-item :span="6" label="订单ID">{{ data.order_no }}</a-descriptions-item>
      <a-descriptions-item :span="3" label="订单来源">{{ formatOrderSource(data.order_source) }}</a-descriptions-item>
      <a-descriptions-item :span="3" label="支付方式">{{ getPayTypeShowName(data) }}
        <span v-if="data.is_cash_delivery == 2" class="ml-4">{{data.order_amount}}元</span>
      </a-descriptions-item>

      <!-- 下单信息 -->
      <a-descriptions-item :span="6" label="下单时间">{{ data.add_time }}</a-descriptions-item>
      <template v-if="data.order_source ===10 || data.order_source ===15">
        <a-descriptions-item :span="3" label="下单人">{{ data.place_order_name }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="下单人电话">{{ data.place_order_phone }}</a-descriptions-item>
      </template>
      <template v-else>
        <a-descriptions-item :span="3" label="联系人">{{ data.delivery_info && data.delivery_info.contacts_name }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="联系人电话">{{ data.delivery_info && data.delivery_info.contacts_phone }}</a-descriptions-item>
      </template>

      <!-- 自提信息 -->
      <template v-if="data.order_type == 1">
        <!-- 自提人 信息 -->
        <a-descriptions-item :span="6" label="自提时间">{{ getGoodsTime(data) }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="取货人">{{ data.pick_up_info.recipient_name }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="取货人电话">{{ data.pick_up_info.recipient_phone }}</a-descriptions-item>
        <!-- 自提 进展 -->
        <a-descriptions-item :span="9" label="自提进展">
          <template>{{getOrderStatus(data.order_status)}}</template>
          <a v-if="data.order_status==3" type="link" class="ml-6" @click="handlerCompletePick">点击完成自提</a>
        </a-descriptions-item>
        <!-- <a-descriptions-item :span="6" label="取货码">{{data.pick_up_info.pick_up_code}}</a-descriptions-item> -->
	<a-descriptions-item :span="3" label="收款主体">{{data.order_financial_account_name}}</a-descriptions-item>
      </template>

      <!-- 配送信息 -->
      <template v-if="data.order_type == 2">
        <!-- 配送进展 信息 -->
        <a-descriptions-item :span="6" label="配送进展">
          <template>{{getOrderStatus(data.order_status)}}</template>
          <a v-if="data.order_status==2 && data.courier_info && data.courier_info.courier_phone" 
              type="link" class="ml-6" @click="handlerQuery(2)">查看地图</a>
        </a-descriptions-item>
        <a-descriptions-item :span="3" label="配送员">{{ data.courier_info && data.courier_info.courier_name }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="配送员电话">{{ data.courier_info && data.courier_info.courier_phone }}</a-descriptions-item>
        <!-- 收货人 信息 -->
        <a-descriptions-item :span="6" label="收货时间">{{ getGoodsTime(data) }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="收货人">{{ data.delivery_info && data.delivery_info.consignee_name }}</a-descriptions-item>
        <a-descriptions-item :span="3" label="收货人电话">{{ data.delivery_info ? data.delivery_info.consignee_phone:'' }}</a-descriptions-item>
          
        <a-descriptions-item :span="9" label="收件地址">{{data.delivery_info.consignee_address}}</a-descriptions-item>
        <a-descriptions-item :span="3" label="收款主体">{{data.is_cash_delivery == 2 ? '' :data.order_financial_account_name}}</a-descriptions-item>
      </template>

      <!-- 备注 -->
      <a-descriptions-item :span="12" label="下单备注">{{ data.order_remarks }}</a-descriptions-item>
      <a-descriptions-item :span="12" label="客服备注">{{ data.customer_service_remarks }}</a-descriptions-item>
      <a-descriptions-item :span="12" label="内部备注">
        <div class="flex">
          <a-textarea style="flex:1;" placeholder="内部备注（用户不可见）"
              v-model="data.private_remarks"
              :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
          <a-button class="ml-2" type="primary" size="small" @click="savePrivateRemarks()">修改备注</a-button>
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <a-divider />
    <div>
      <div class="antd-pro-pages-profile-basic-style-title">订单商品</div>
      <base-table
        ref="orderDetilTableRef"
        id="orderDetilTableID"
        :columnsData="detail_columns"
        :rowKey="detail_rowKey"
        :tableData="tableData"
      >
        <template #status="{ text }">
          <!-- <a-tag v-if="text == 1" color="green">正常</a-tag> -->
          <a-tag v-if="text == 2" class="mr-0" color="gray">已拆单顺丰</a-tag>
          <a-tag v-if="text == 3" class="mr-0" color="red">已申请退款</a-tag>
          <a-tag v-if="text == 4" class="mr-0" color="red">已退款</a-tag>
        </template>
      </base-table>

      <div
        class="mt-2 justify-between"
        v-if="data.cake_accessory_list && data.cake_accessory_list.length > 0"
      >
        <div>
          <span class="text-sm font-bold text-black">蛋糕配件</span>
        </div>
        <div v-for="(item, i) in data.cake_accessory_list" :key="i">
          <div class="flex mt-2">
            <div>{{ item.goods_name }}
                <!-- <span class="ml-2" style="color:#aaa;">{{getSkuInfo(item.order_goods_id)['flavor_name']}} / {{getSkuInfo(item.order_goods_id)['specification_name']}}</span> -->
                <span class="ml-2" style="color:#aaa;">{{getSkuInfo(item.order_goods_id)['specification_name']}}</span>
            </div>
            <div class="ml-10">蜡烛：{{ formatBirthdayCandle(item.birthday_candle) }}</div>
            <div class="ml-10">餐具：{{ item.diners_number }}</div>
            <div class="ml-10">一次性餐具：{{item.plastic_tableware_number}}</div>
            <div class="ml-10">生日牌：{{ item.birthday_card }}</div>
            <div class="ml-10">贺卡：<p class="pre">{{ item.birthday_greeting_card }}</p></div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <a-button class="bottom-btn" type="link" @click="isShowFlow = true">订单流水</a-button>
      <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">操作记录</a-button>
      <template>
        <a-button v-if="invoiceStatus==1" class="bottom-btn" type="link" @click="isAddInvoice = true"
          >开票未开</a-button
        >
        <a-button v-if="invoiceStatus==2" class="bottom-btn" type="link" @click="isShowDetailInvoice = true"
          >发票已开</a-button
        >
      </template>
      
    </div>

    <!-- 物流 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="data.order_no" 
        :hasDelivery="query_delivery" 
        :hasExpress="query_express" />
	
    <!-- 弹窗：流水、操作记录 -->
    <flow-modal v-if="isShowFlow" :show.sync="isShowFlow" :orderId="data.order_no" />
    <opt-modal v-if="isShowOptLog" :show.sync="isShowOptLog" :orderId="data.order_no" />
    <!-- 弹窗：开发票 -->
    <order-invoice-upload v-if="isAddInvoice" :show.sync="isAddInvoice" :requestID="data.suborder_no" @ok="initData"/>
    <order-invoice-detail v-if="isShowDetailInvoice" :show.sync="isShowDetailInvoice" :requestID="data.suborder_no"/>
    
    <!-- 打印弹窗 -->
    <print-modal v-if="isShowPrint"
                 :show.sync="isShowPrint"
                 :orderNo="data.order_no"
                 :subOrderNo="data.suborder_no" />
    <!-- 取消弹窗（包含其他支付） -->
    <cancel-modal v-if="isShowCancel"
         :show.sync="isShowCancel"
         :payInfo="data"
         @ok="handlerCancelSure" />

    <!-- 自提：货到付款 -->
    <a-modal :visible.sync="isShowCashPick"
             title="确认已收款"
             destroyOnClose
             width="400px"
             @ok="handlerCompletePick"
             @cancel="isShowCashPick=false">
      <div class="mt-4 mb-4">
        <div class="mb-4">该订单是货到款订单，请确认已收款</div>
        <a-checkbox v-model="isCheckCash">我已收款 {{data.order_amount}} 元</a-checkbox>
      </div>
    </a-modal>

  </div>

</div>
</template>

<script>
import { 
  getShopOrderDesc, 
  updateOrderStatus, 
  getInvoiceApplyDesc,
  cancelOrder
} from "@/api/shop.js";
import { 
  updatePrivateRemarks,
} from "@/api/customer"
import pageData from "./columns";
import {
  formatBirthdayCandle,
  formatOrderSource,
  payTypeList,
  subCODPayTypeList,
  // subOnAccountPayTypeList
} from "@/utils/type";
import QueryWindow from "@/components/order/queryWindow";
import FlowModal from "@/components/order/flow-modal.vue";
import OptModal from "@/components/order/opt-modal.vue";
import OrderInvoiceUpload from './components/order-invoice-upload.vue'
import OrderInvoiceDetail from './components/order-invoice-detail.vue'
import printModal from "./components/print-modal.vue"
import CancelModal from '@/components/order/cancel-modal.vue'

export default {
  components: {
    QueryWindow,
    FlowModal,
    OptModal,
    OrderInvoiceUpload,
    OrderInvoiceDetail,
    printModal,
    CancelModal
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ...pageData,
      requestID: "",
      tableData: [],
      data: {
        pick_up_info: {
          pick_up_code: "",
        },
      },
      invoiceStatus: 0,
      isAddInvoice: false,
      isShowDetailInvoice: false,
      isShowFlow: false,
      isShowOptLog: false,
      isShowQuery: false,
      query_delivery: false,
      query_express: false,
      isShowPrint: false,
      isShowCancel: false,

      isShowCashPick: false,
      isCheckCash: false,
    }
  },
  created() {
    if(this.orderNo){ // 如果是抽屉页
      // this.isInDrawer = true
    }else if(this.$route.query.order_no){ // 如果是新开页
      this.orderNo = this.$route.query.order_no
    }

    this.initData()
  },
  methods: {
    formatBirthdayCandle,
    formatOrderSource,

    async initData() {
      const { data, code } = await getShopOrderDesc({
        suborder_no: this.orderNo,
      });
      
      if (code === 0) {
        this.data = data;
        this.tableData = data.goods_list;
      }
      getInvoiceApplyDesc({ suborder_no: this.orderNo }).then(res=>{
        if(res.data){
          this.invoiceStatus = res.data.audit_status
        }
      })
    },
    // 完成自提
    async handlerCompletePick() {
      const params = {
        suborder_no: this.orderNo,
        status: 30,
      }
      if(this.data.is_cash_delivery==2){
        if(!this.isShowCashPick){
          this.isShowCashPick = true
          return
        }
        if(!this.isCheckCash){
          this.$message.warning("请先收款")
          return
        }
        params.cash_delivery_amount = this.data.order_amount
      }
      
      const { code } = await updateOrderStatus(params)
      if (code === 0) {
        this.isShowCashPick = false
        this.isCheckCash = false
        this.$message.success("已完成")
        this.initData()
      }
    },

    // 取消订单
    handlerCancelSure(refundData){
      let params = {
        ...refundData,
        suborder_no: this.data.suborder_no
      }
      const _this = this
      cancelOrder(params).then(res => {
        if(res.code == 0){
          _this.isShowCancel = false
          _this.$message.success("已取消")
          _this.initData()
        } 
      })
    },

    async savePrivateRemarks(){
      updatePrivateRemarks({
        order_no: this.data.order_no,
        private_remarks: this.data.private_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
        }
      })
    },

    getGoodsTime(data) {
      let start, end
      if (data.order_type === 2) {
        start = data.delivery_info.delivery_time_start
        end   = data.delivery_info.delivery_time_end
      } else {
        start = data.pick_up_info.pick_up_goods_time_start
        end   = data.pick_up_info.pick_up_goods_time_end
      }
      if(start){
        if(start == end){
          return (
            start.replace(/:00$/, "")
          )
        }else{
          return (
            start.replace(/:00$/, "") + "-" + end.split(" ")[1].replace(/:00$/, "")
          )
        }
      }
      return ""
    },
    getOrderStatus(id) {
      // 1-待配送，2-配送中，3-自提未完成，4-已完成，5-已取消
      const statusOBj = {
        1: "待配送",
        2: "配送中",
        3: "待自提",
        4: "已完成",
        5: "已取消",
      }
      return statusOBj[id]
    },
    // 支付方式（给网点展示的略有隐藏）
    getPayTypeShowName(data){
      if(data.pay_type == 10){ // 货到付款：货到付款(刷卡)
        return "货到付款"
      }else if(data.is_cash_delivery == 2){ // 货到付款 转在线支付 但没支付
        return "货到付款"
      }else if((data.order_source == 1 || data.order_source == 2) && data.pay_type == 2){ // 小程序订单（微信）
        return "微信"
      }else if(data.order_source == 3 || data.order_source == 4 || data.order_source == 5 || data.order_source == 6){ // 网站订单
        return "科技公司挂帐"
      }else if(data.pay_type == 30){ // 挂账：第三方平台
        return "科技公司挂帐"
      }else if(data.order_source == 10 && data.pay_type == 20){ // 客服下单-其他支付
        return "科技公司挂帐"
      }else if(data.order_source == 15 && data.pay_type == 20){ // 网点下单-其他支付
        return "挂账（门店）"
      }else{ // x支付（应该走不到这个逻辑）
        return this.getPayTypeName(data.pay_type)
      }
    },
    // 支付方式
    getPayTypeName(id) {
      let item = payTypeList.find(el=>{
        if(id == el.id) return el
      })
      return item && item.name || ""
    },
    getSubPayTypeName(id) {
      let item = subCODPayTypeList.find(el=>{
        if(id == el.id) return el
      })
      // let item = subCODPayTypeList.concat(subOnAccountPayTypeList).find(el=>{
      //   if(id == el.id) return el
      // })
      return item && item.name || ""
    },

    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },

    getSkuInfo(order_goods_id){
      let sku = this.data.goods_list.find(el=>{
        return el.order_goods_id == order_goods_id
      })
      return sku || {}
    },
  },
}
</script>

<style lang="less">

.descri-class.ant-descriptions-bordered.ant-descriptions-middle
  .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 10px;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
}
.pre{
  white-space: pre;
  display: inline-block;
  vertical-align: top;
}
</style>
