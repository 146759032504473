<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="70%"
      title="发票详情"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <div class="header">
        <h4>订单支付:</h4>
        <span v-if="Number(invoiceData.vip_card_pay_amount)>0">会员卡 {{ invoiceData.vip_card_pay_amount }}元</span>
        <span v-if="Number(invoiceData.stored_value_card_pay_amount)>0">储值卡 {{ invoiceData.stored_value_card_pay_amount }}元</span>
        <span v-if="Number(invoiceData.wx_pay_amount)>0">微信 {{ invoiceData.wx_pay_amount }}元</span>
        <span v-if="Number(invoiceData.alipay_amount)>0">支付宝 {{ invoiceData.alipay_amount }}元</span>
        <span v-if="Number(invoiceData.cash_delivery_amount)>0">货到付款 {{ invoiceData.cash_delivery_amount }}元</span>
      </div>
      
      <base-table
        rowKey="invoiceTableRowKey"
        :columnsData="columns_invoice"
        :tableData="invoiceTableData"
      >
        <template #type="{ text }">
          <span v-if="text == 1">软点</span>
          <span v-else-if="text == 2">西点</span>
          <span v-else>蛋糕</span>
        </template>
      </base-table>
      <!-- 旺店通账户 分仓库 -->
      <template v-if="expressTableData && expressTableData.length">
        <h5 class="mt-3" style="color:#f00;">* 此订单的旺店通子订单被拆分为多仓库发货，所以需要人工分仓库开票</h5>
        <base-table
          :columnsData="columns_express"
          :tableData="expressTableData"></base-table>
      </template>

      <div class="footer">
        <a-form-model
          layout="horizontal"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
        >
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="申请金额">{{ invoiceInfo.amount }} 元</a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                label="邮箱">{{ invoiceInfo.email }}</a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item
                label="状态" v-if="invoiceData.audit_status == 1">未开票</a-form-model-item>
              <a-form-model-item
                label="状态" v-if="invoiceData.audit_status == 2">已开票({{invoiceData.invoice_type == 1?'电子发票':'纸质发票'}})</a-form-model-item>
            </a-col>
          </a-row>

          <!-- 企业 -->
          <template v-if="invoiceInfo.type==1">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">企业</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item
                  label="抬头">{{invoiceInfo.name}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                 <a-form-model-item
                  label="税号">{{invoiceInfo.corporate_duty_paragraph}}</a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="电话">{{ invoiceInfo.phone }}</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item 
                  label="开户行">{{invoiceInfo.open_bank}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item  
                  label="账号">{{invoiceInfo.bank_account}}</a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item  :labelCol="{span:2}" :wrapperCol="{span:22}"  label="注册地址">{{invoiceInfo.registered_address}}</a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 个人 -->
          <template v-if="invoiceInfo.type==2">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">个人</a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item
                  label="抬头">{{invoiceInfo.name}}</a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item 
                  label="电话">{{invoiceInfo.phone}}</a-form-model-item>
              </a-col>
            </a-row>
          </template>
          
          <!-- 发票图片 -->
          <template v-if="invoiceData.audit_status == 2">
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="上传发票">
                  <img class="img-class"
                    v-for="(item, index) in imgList"
                    :src="item.file_url"
                    :key="index"
                    @click="handlerBigImg(item)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :span="24">
                <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="开票备注">
                  {{invoiceData.audit_remarks}}
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

        </a-form-model>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="发票图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="handlerCancel"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import {columns_invoice} from "../../../trading-manage/order-invoice/columns.js"
import {
  getInvoiceApplyDesc,
  // openInvoce,
} from "@/api/shop.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: [Number,String],
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    return {
      columns_invoice: columns_invoice,
      previewVisible: false,
      previewImage: "",
      form: {},
      imgList: [],
      invoiceData: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      invoiceTableData: [],
      invoiceInfo: {},
      expressTableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getInvoiceApplyDesc({ suborder_no: this.requestID })
      if (code == 0) {
        this.invoiceData = data
        this.invoiceTableData = data.goods_list
        this.invoiceTableData.map((item, index) => {
          item["invoiceTableRowKey"] = index
          return item
        })
        this.expressTableData = data.wdt_order_list
        this.invoiceInfo = data.invoice_info
        this.imgList = data.invoice_info.invoice_files
      }
    },
    handlerBigImg(e) {
      this.previewImage = e.file_url
      this.previewVisible = true
    },
    handlerCancel() {
      this.previewVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.preview-class /deep/ .ant-modal-body {
  padding: 10px;
}
.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}

.footer {
  margin-top: 20px;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>
